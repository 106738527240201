/* eslint-disable  no-unused-vars */
import { UserProfile } from '@auth0/nextjs-auth0/client'

const rolesKey = (process.env.NEXT_PUBLIC_AUTH0_ENV_KEY as string) + '/roles'

export enum Roles {
  ADMIN = 'Admin',
  TRADER = 'Trader',
  SALES = 'Sales',
  OPTAXE_ADMIN = 'OptAxeAdmin'
}

const hasRole =
  (role: Roles) =>
  (user: UserProfile | undefined, clientRolesKey = ''): boolean => {
    return Boolean(
      user &&
        user[clientRolesKey] &&
        (user[clientRolesKey] as Array<string>).includes(role)
    )
  }

export const hasAdminRole = hasRole(Roles.ADMIN)

export const hasOptaxeAdminRole = hasRole(Roles.OPTAXE_ADMIN)

export const hasTraderRole = hasRole(Roles.TRADER)

export const hasSalesRole = hasRole(Roles.SALES)

// To be used Server side only
export const isAdmin = (user: UserProfile): boolean => {
  return (user[rolesKey] as string[]).includes(Roles.ADMIN)
}

export const getRoles = (user: UserProfile): string[] => {
  return user[rolesKey] as string[]
}
