export enum Colors {
  transparent = 'transparent',
  current = 'currentColor',
  white = '#FFFFFF',
  black = '#000000',
  // Grays
  disabledInputGray = '#F5F5F5',
  lightGray = '#F0F0F0',
  baseGray = '#A7A7A7',
  borderGray = '#DAD6D6',
  pathGray = '#D9D9D9',
  textLightGray = '#ACACAC',
  textGray = '#474747',
  textGrayLight = '#696F8C',
  iconGray = '#CECECE',
  iconDarkGray = '#3D455C',
  // Blues
  fadedBlue = '#E9EFFA',
  lightBlue = '#6797F5',
  traderBlue = '#277DA1',
  royalBlue = '#1246A8',
  overlayBlue = '#17244B',
  darkBlueGray = '#2D3653',
  darkBlue = '#101A38',
  deepBlue = '#0A0F22',
  darkerBlue = '#04070F',
  // Greens
  fadedGreen = '#DAEDE3',
  traderMoss = '#90BE6D',
  traderGreen = '#43AA8B',
  newGreen = '#03DA9B',
  successGreenDarkMode = '#43AA72',
  successGreen = '#0A6C39',
  // Yellows
  fadedYellow = '#FFF1D0',
  traderYellow = '#F9C74F',
  processYellowDarkMode = '#E6A200',
  processYellow = '#E1A617',
  // Oranges
  traderOrange = '#F3722C',
  // Reds
  lightRed = '#f37f7f',
  red = '#FF5757',
  traderRed = '#F94144',
  errorRed = '#EE4444',
  notificationRed = '#E12626',
  fadedRed = '#F4E2E2',
  // Pink
  pink = '#C01E5F',
  // Purples
  traderMauve = '#C47BDE',
  traderPurple = '#9262C2'
}
