/**
 * The Hasura relay endpoint (beta) accepts a base64 encoded string as the
 * user id if the ID is on a root node. For inserts where the user is a linked
 * field, we need to decode and extract the user id from the base64 string.
 * See https://hasura.io/docs/latest/schema/postgres/relay-schema/
 * @param base64
 */
const getIDFromBase64 = (base64: string): string => {
  if (!base64) {
    console.warn('Base64 string is not defined')

    return ''
  }

  return JSON.parse(atob(base64))[3]
}

export default getIDFromBase64
